import React from 'react';
import { Routes, Route} from "react-router-dom";
import { Home, Resume, Travel } from './components/index.js';
import './App.css';
import { Navigation } from './components/navigation/Navigation.js';
require('purecss')

function App() {
  return (
    <div>
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/resume" element={<Resume />} />
        <Route exact path="/travel-plan-Nxl9LmtlbwH9" element={<Travel />} />
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App;