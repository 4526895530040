import React from 'react';
import './travel.css'

const Travel = () => {
  return (
    <div className="travel pagebody">
        <div>
          <h1>Alligators and Ocean </h1>
          <h2> Everglades & The Keys</h2>
          <div>
            <h2>Stay Destinations</h2>
            <ul>
              <li>Key Biscayne/Miami (Wed)</li>
              <li>Key Largo (Thurs)</li>
              <li>Key West (Friday, Saturday)</li>
              <li>Key Largo or Miami Beach? (Sunday)</li>
            </ul>
          </div>
          <hr />
          <div>
            <h2>To Do Items</h2>
            <ul>
              <li>Decide on Programs We Want to Take in Everglades</li>
              <li>Book Hotels</li>
              <li>Get Rental Car</li>
              <li>Get Tickets to Turtle Hospital</li>
              <li>Get Tickets for Sailing? </li>
            </ul>
          </div>
          <hr />
          <div>
            <h2>Additional Reading</h2>
            <div>
              <a href="https://www.npca.org/articles/2745-an-insider-s-guide-to-everglades-beyond?gad_source=1&gclid=Cj0KCQiAyc67BhDSARIsAM95QzvM9KnQA17Ub8BLbnqX2dP8q0cl2FLE4PdXN8x03t7w46M_BzoqI8MaAqe7EALw_wcB">Key Biscayne</a>
            </div>
            <div>
              <a href="https://www.npca.org/articles/2745-an-insider-s-guide-to-everglades-beyond?gad_source=1&gclid=Cj0KCQiAyc67BhDSARIsAM95QzvM9KnQA17Ub8BLbnqX2dP8q0cl2FLE4PdXN8x03t7w46M_BzoqI8MaAqe7EALw_wcB">Drive to Everglades</a>
            </div>
            <div>
              <a href="https://pennekamppark.com/glassbottom-boat">John Pennekamp Glass Bottom Boat Tour</a>
              <div>
                <a href="https://www.turtlehospital.org/">Turtle Hospital</a>
              </div>
            </div>
            <div>
              <a href="https://fla-keys.com/">Key West</a>
            </div>
            <div>
              <a href="https://www.fws.gov/refuge/key-west">Keys National Wildlife Refuge</a>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Travel;
